import React, { createContext, useContext, useEffect, useState } from "react";
import { Avatar, Button, Drawer, InputNumber, List, notification } from "antd";

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

const CartProvider = ({ children }) => {
  const [cartValue, setCartValue] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [showCartList, setShowCartList] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState([]);
  const [branchDetail, setBranchDetail] = useState(null);
  const [tipPrice, setTipPrice] = useState(20);
  const [totalPrice, setTotalPrice] = useState([]);
  const handleCartList = () => setShowCartList((prev) => !prev);

  // selected priduct details 

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedHight, setSelectedHight] = useState(null);
  const [selectedwidth, setSelectedwidth] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [selectedDiscount,setSelectedDiscount]= useState(null)
  /////====gestdetail====///

  // price state 

  const [amountDetails, setAmountDetials] = useState({
    totalAmount: "",
    subTotal: "",
    texAmount: "",
  });

  const [guestDetails, setGuestDetails] = useState(null);

  const updateGuestDetails = (details) => {
    setGuestDetails(details);
  };

  // Function to calculate total item price
  const totalItemPrice = () => {
    return cartValue.reduce(
      (total, product) => total + parseFloat(product.price * product.itemQty),
      0
    );
  };

  // Function to calculate sales tax
  const salesTextPrice = (itemTotal) => {
    return (itemTotal * 10) / 100;
  };

  // Function to calculate and update total price in state
  const calculateTotalPrice = () => {
    const total = totalItemPrice() + salesTextPrice(totalItemPrice()) + tipPrice;
    setTotalPrice(total); // Set the total price state
  };

  // ===================== Added Into Cart ===============
  const handleAddCart = (value) => {
    console.log('cart value'+ JSON.stringify(value));
    if (cartValue?.length > 0) {

      const index = cartValue.findIndex(valueCheck => valueCheck.productid === value.productid && valueCheck.productColor === value.productColor && valueCheck.productSize === value.productSize);
      console.log("index" + index)
      if (index >= 0) {
        // console.log('true');
        const previousData = cartValue;

        previousData[index].productQuantity = previousData[index].productQuantity + 1;
        previousData[index].product_price = parseInt(previousData[index].productQuantity) * parseInt(previousData[index].product_price)
        setCartValue(previousData)
        openNotification(previousData[index], `Item Quantity Increase by ${previousData[index].productQuantity}`)
      } else {
        setCartValue(prevValue => [...prevValue, value]);
        openNotification(value, "Product Added into cart")
      }

    } else {
      setCartValue([value]);
      openNotification(value, "Product Added into cart")
    }
  };


  //   =================== Open Notiification ================
  const openNotification = (value, message) => {
    const key = `open${Date.now()}`;
    const listDesign = (
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={value.productImage} />}
            title={`${value.productName} ${value.colorName}`}
            description={`Price: ${value.product_price}  Qty: ${value.productQuantity} Size:${value.productSize}`}
          />
        </List.Item>
      </List>
    );
    api.open({
      message: message,
      description: listDesign,
      key,
    });
  };
  //////branchdetail//
  const contextbrnchdata = (data) => {
    setBranchDetail(data);
    // You can include any other logic or state updates you need here
  };
  ////branch id to c =======
  const updateBranchId = (id) => {
    setBranchId(id);
  };

  const contextValue = {
    cartCount,
    handleCartList,
    handleAddCart,
    showCartList,
    cartValue,
    branchDetail,
    salesTextPrice,
    setBranchDetail,
    branchDetail,
    setBranchDetail,
    contextbrnchdata,
    guestDetails,
    setGuestDetails,
    updateGuestDetails,
    branchData,
    branchId,
    updateBranchId,
    // Removed updateBranchData because it's not defined,
    setCartValue,
    setCartCount,
    totalItemPrice,
    setShowCartList,
    totalPrice,
    setTipPrice,
    tipPrice,
    setTipPrice,
    selectedProduct,
    setSelectedProduct,
    setSelectedSize,
    setSelectedColor,
    setSelectedPrice,
    setSelectedImages,
    selectedSize,
    selectedColor,
    selectedPrice,
    selectedImages,
    amountDetails,
    setSelectedWeight,
    selectedWeight,
    setSelectedHight,
    selectedHight,
    setSelectedwidth,
    selectedwidth,
    selectedDiscount,
    setSelectedDiscount,
  };
  //brnchdetai

  // =================== add to local Storage ==================

  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  useEffect(() => {
    const cartdata = JSON.parse(localStorage.getItem("cart"))
    if (cartdata && cartdata !== null) {
      setCartValue(cartdata)
      setCartCount(cartValue.length)
      if (cartValue && cartValue.length >= 0) {
        setCartValue((prevCartValue) => {
          let total = 0;
          prevCartValue.forEach((product) => {
            total += parseFloat(product.product_price);
          });
          const totalTax = (total * 10) / 100;
          const tax = parseFloat(totalTax.toFixed(2));
          const itemTotal = parseFloat(total.toFixed(2));
          const totalAdd = itemTotal + tax;
          const finalAmount = parseFloat(totalAdd.toFixed(2));

          // Use the callback function to ensure state is updated
          setAmountDetials((prevAmountDetails) => ({
            ...prevAmountDetails,
            texAmount: tax,
            subTotal: itemTotal,
            totalAmount: finalAmount,
          }));
          return prevCartValue;
        });
      }
    }
  }, []);

  return (
    <CartContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
