import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Drawer,
  Space,
  Badge,
  Divider,
  Empty,
  Dropdown,
  ConfigProvider,
  Popover,
} from "antd";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import "../Css/Header.css";
import logo from "../Assert/kartaria logo.png";
import {
  FaPinterestP,
  FaWhatsapp,
} from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import { IoBag } from "react-icons/io5";
import { useCartContext } from "../context/addToCart";
import { getProfile, getToken } from "../services/localStorageServices";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../features/authSlice";
import { FiMinus, FiPlus } from "react-icons/fi";
import { IoCall } from "react-icons/io5";
const items = [
  {
    key: "1",
    label: <Link to="/account/sign-in">sign in</Link>,
  },
  {
    key: "2",
    label: <Link to="/account/sign-up">sign up</Link>,
  },
];

const Header = () => {
  // const {cartCount } = useCartContext();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const { access_token } = getToken();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { cartValue, setCartCount, setCartValue, cartCount } = useCartContext();
  const { profile } = getProfile();
  const [visibleCategories, setVisibleCategories] = useState(5);
  const [getCat, setGetCat] = useState([]);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    setVisible(false);
    localStorage.clear();
    localStorage.removeItem("category");
    sessionStorage.removeItem("bis_data");
    sessionStorage.clear();
    navigate("/");
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [opens, setOpens] = useState(false);
  const showDrawers = () => {
    setOpens(true);
  };
  const onCloses = () => {
    setOpens(false);
  };
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const handleClosePopover = () => {
    setVisible(false);
  };
  const removeCartData = (index) => {
    setCartValue((prevData) => {
      const updatedData = prevData.filter((_, i) => i !== index);
      addtoLocatStorage(updatedData);
      setCartCount(updatedData.length);
      return updatedData;
    });
  };
  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };
  // const getCategory
  useEffect(() => {
    setGetCat(JSON.parse(localStorage.getItem("category")));
  }, []);

  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);

  //   console.log(getCat);
  const content = (
    <div className="popover-content-custom">
      <div>
        <Link to="/profiles" onClick={handleClosePopover}>
          Profile
        </Link>
      </div>
      <div onClick={handleLogout}>
        <Link>Logout</Link>
      </div>
    </div>
  );

  const handleLoadMore = () => {
    setVisibleCategories((prevVisible) => prevVisible + 5); // Show 5 more categories
  };



  const handleIncrement = (index) => {
    setCartValue((prevData) => {
      const updatedData = [...prevData];
      if (updatedData[index]) { // Check if index is valid
        updatedData[index].productQuantity += 1;
        addtoLocatStorage(updatedData);
        setCartCount(updatedData.length);
      }
      return updatedData;
    });
  };

  const handleDecrement = (index) => {
    setCartValue((prevData) => {
      const updatedData = [...prevData];
      if (updatedData[index] && updatedData[index].productQuantity > 1) {
        updatedData[index].productQuantity -= 1;
        addtoLocatStorage(updatedData);
        setCartCount(updatedData.length);
      }
      return updatedData;
    });
  };

  const calculateSubtotal = () => {
    return cartValue.reduce((acc, current) => {
      return acc + current.productQuantity * current.product_price;
    }, 0);
  };

  return (
    <>
      <div className="outter-navbar">
        <div className="container navbar-main-container">
          <div>
            <Space>
              <Button className="header-drawer" type="" onClick={showDrawer}>
                <RxHamburgerMenu />
              </Button>
              <Drawer
                title=""
                placement={placement}
                width={500}
                onClose={onClose}
                open={open}
              >
                {/* {!profile && ( */}
                  <Link
                    to="/account/sign-in"
                    className="header-drawer-sign-in-sign-out"
                    onClick={onClose}
                  >
                    <div className="d-flex justify-content-start gap-3 align-items-center mb-2">
                      <label>
                        <FaUserCircle className="fs-5" />
                      </label>
                      <label className="fs-5 fw-bold">Login</label>
                    </div>
                    <Divider />
                  </Link>
                {/* )} */}
                {/* <h5 className="fw-drawer-main-head">
                  <Link to="/" onClick={onClose}>
                    Home
                  </Link>
                </h5> */}
                {/* <Divider /> */}
                {/* <h5 className="drawer-main-head-products-category">
                  Products Category
                </h5> */}
                {/* <Divider /> */}
                <div className="d-flex navbar-product-category">
                  <div>
                    <div
                      className="navbar-product-category-head-main-con"
                    >
                      <Link
                        to='/'
                        onClick={onClose}
                      >
                        Home
                      </Link>
                    </div>
                    <div
                      className="navbar-product-category-head-main-con"
                    >
                      <Link
                        to='/product/all'
                        onClick={onClose}
                      >
                        All Products
                      </Link>
                    </div>
                    {getCat &&
                      getCat?.length > 0 &&
                      getCat?.slice(0, visibleCategories).map((v, i) => (
                        <div
                          key={i}
                          className="navbar-product-category-head-main-con subcat"
                        >
                          <Link
                            to={`/product/${v.category_slugs}`}
                            onClick={onClose}
                          >
                           - {v.category_name}
                          </Link>
                        </div>
                      ))}
                    {visibleCategories < getCat.length && (
                      <button
                        className="fw-bold fs-6 load-more"
                        onClick={handleLoadMore}
                      >
                        Load More
                      </button>
                    )}
                    <div className="navbar-product-category-head-main-con">
                      <Link to="/About-us" onClick={onClose}>
                        About us
                      </Link>
                    </div>
                    <div className="navbar-product-category-head-main-con">
                      <Link to="/TermsofService" onClick={onClose}>
                        Terms & Conditions
                      </Link>
                    </div>
                    <div className="navbar-product-category-head-main-con">
                      <Link to="/PrivacyPolicie" onClick={onClose}>
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="navbar-product-category-head-main-con">
                      <Link to="/contact-page" onClick={onClose}>
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <Divider /> */}
                {/* <h5 className="drawer-main-head-products-category">
                  Useful Links
                </h5> */}
                {/* <Divider /> */}
                {/* <div className="d-flex navbar-product-category">
                  <div>
                    <Link to="/About-us" onClick={onClose}>
                      About us
                    </Link>
                  </div>
                  <div>
                    <Link to="/contact-page" onClick={onClose}>
                      Contact us
                    </Link>
                  </div>
                  <div>
                    <Link to="/TermsofService" onClick={onClose}>
                      Terms & Conditions
                    </Link>
                  </div>
                  <div>
                    <Link to="/PrivacyPolicie" onClick={onClose}>
                      Privacy Policy
                    </Link>
                  </div>
                </div> */}
                {/* <Divider /> */}
                <div className="navbar-social-media">
                  <div className="navbar-social-media-inner-div">
                    <div>
                      <a href="https://www.facebook.com/share/5AbV8V5VJQ6tCDfL/?mibextid=LQQJ4d" target="_blank">
                        <FaFacebookF />
                      </a>
                    </div>
                    <div>
                      <a href="https://www.instagram.com/katariashubli?igsh=MWQ1dGtyOTQzeTd3cA%3D%3D" target="_blank">
                        <TiSocialInstagram />
                      </a>
                    </div>
                    <div>
                      <a href="https://wa.me/+919321032490" target="_blank">
                        <FaWhatsapp />
                      </a>
                    </div>
                    <div>
                      <a href="tel:+919321032490" target="_blank">
                        <IoCall />
                      </a>
                    </div>
                    <div>
                      <a href="https://in.pinterest.com/" target="_blank">
                        <FaPinterestP />
                      </a>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Space>
          </div>
          <div className="nav-logo-div">
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="navbar-right-main">
            <div>
              {token ? (
                <div>
                  <Popover
                    placement="bottomRight"
                    content={content}
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={["hover", "click"]}
                  >
                    <Button type="text">
                      <FaUserCircle size={24} />
                    </Button>
                  </Popover>
                </div>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        /* here is your component tokens */
                      },
                    },
                  }}
                >
                  <Dropdown
                    menu={{
                      items,
                    }}
                    className="sgin-in-sign-out-dropdown-header header-drawer"
                    placement="bottomLeft"
                  >
                    <Button className="header-login-register-on-hover">
                      sign in / sign up
                    </Button>
                  </Dropdown>
                </ConfigProvider>
              )}
            </div>
            <div className="navbar-addTo-cart-icon">
              <Badge count={cartValue.length} showZero>
                <IoBag style={{ fontSize: "26px" }} onClick={showDrawers} />
              </Badge>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Shopping Cart"
        placement="right"
        onClose={onCloses}
        open={opens}
      >
        <div className="Product-addedCart-Side-Bar">
          {cartValue.length !== 0 ? (
            <>
              {cartValue?.map((v, i) => (
                <div className="Product-addedCart-Side-details">
                  <div className="Product-addedCart-Image-con">
                    <div className="Product-addedCart-Image">
                      <img src={v.productImage} alt="" />
                    </div>
                  </div>
                  <div className="Product-details-addedCart-price-name">
                    <h2>{v.productName}</h2>
                    <h3>
                      <span>Size : </span>
                      {v.productSize}
                    </h3>
                    <h3>
                      <span>Price : </span>₹ {v.product_price}
                    </h3>
                    <div className="Peoduct-Details-Section-1-Quantity-main-con">
                      <div className="Product-details-Quantity-counter-material">
                        <button onClick={() => handleDecrement(i)}>
                          <FiMinus />
                        </button>
                        <h3>{v.productQuantity}</h3>
                        <button onClick={() => handleIncrement(i)}>
                          <FiPlus />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="Product-details-addedCart-cancel-btn">
                    <div
                      className="Product-details-addedCart-cancel-btn-con"
                      onClick={() => removeCartData(i)}
                    >
                      <RxCross2 />
                    </div>
                  </div>
                </div>
              ))}
              <Divider />
              <div className="shopping-cart-subtotal">
                <label>Subtotal</label>
                <label>
                  <label>₹ {calculateSubtotal()}</label>
                </label>
              </div>
              <div className="Product-details-Addedcart-btn-ViewCart">
                <Link to="/checkOutPage">
                  <button onClick={onCloses}>CheckOut</button>
                </Link>
              </div>
            </>
          ) : (
            <Empty description="Your cart is empty" />
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Header;
