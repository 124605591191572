import React from "react";
import "../Css/IconSec.css";
import free_alteration_at_store from "../Assert/free-alteration-at-store.png"
import premium_quality from "../Assert/premium-quality.png";
import designer_wear_brand from "../Assert/designer-wear-brand.png";
import secure_payment from "../Assert/secure-payment.png";
const IconSec = (() => {
    return (
        <>
            <section className="section-icons-main">
                <div className="container">
                    <div className="section-icons-main-details-con">
                        <div className="row">
                            <div className="col-3">
                                <div className="section-icons-details-main-con">
                                    <div className="section-icons-details-main-con-images">
                                        <div className="section-icons-details-con-images">
                                            <img src={free_alteration_at_store} alt="free_alteration_at_store" />
                                        </div>
                                    </div>
                                    <div className="section-icons-details-info-main-con">
                                        <h3>free alteration at store</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="section-icons-details-main-con">
                                    <div className="section-icons-details-main-con-images">
                                        <div className="section-icons-details-con-images">
                                            <img src={premium_quality} alt="free_alteration_at_store" />
                                        </div>
                                    </div>
                                    <div className="section-icons-details-info-main-con">
                                        <h3>premium quality</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="section-icons-details-main-con">
                                    <div className="section-icons-details-main-con-images">
                                        <div className="section-icons-details-con-images">
                                            <img src={designer_wear_brand} alt="free_alteration_at_store" />
                                        </div>
                                    </div>
                                    <div className="section-icons-details-info-main-con">
                                        <h3>designer wear brand</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="section-icons-details-main-con">
                                    <div className="section-icons-details-main-con-images">
                                        <div className="section-icons-details-con-images">
                                            <img src={secure_payment} alt="free_alteration_at_store" />
                                        </div>
                                    </div>
                                    <div className="section-icons-details-info-main-con">
                                        <h3>secure payment</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
});

export default IconSec;