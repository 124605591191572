import React, { useEffect, useState } from "react";
import "../../Css/account.css";
// import login from "../../Images/Login/login.gif";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Checkbox, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import Google from "../../Images/google-removebg-preview.png";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { setProfile, storeToken, setdeliveryaddress } from "../../services/localStorageServices";
// import GoogleLogin from "react-google-login";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { post } from "../../apiService";

export default function SignIn() {


  // google login 

  // const clientId = "413735203093-tt019nmdfqqkff5vf69lhskudqtph6m0.apps.googleusercontent.com";

  // const onSuccess = async (res) => {
  //   try {
  //     const formDataToSend = new FormData();
  //     // Append data to FormData object
  //     formDataToSend.append('User_Name', res.profileObj.name);
  //     formDataToSend.append('User_Email', res.profileObj.email);
  //     formDataToSend.append('action', "login");
  //     const response = await axios.post('https://jutiepie.in/api/Googlelogin.php?', formDataToSend);
  //     if (response.data) {
  //       dispatch(setUserToken({ token: response.data.token }));
  //       storeToken(response.data.token);
  //       setProfile(response.data.profile);
  //       navigate("/");
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  //   console.log("LOGIN SUCCESS! Current user: ", res.profileObj)
  // }

  const onFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res)
  }

  ////// End Google Login ///////
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    if (token) {
      navigate("/")
    }
  }, [token])

  const tokenid = sessionStorage.getItem("access_token")

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);

    try {
      // Construct the data to be sent to the API
      const formData = new FormData();
      formData.append("action", "login");
      formData.append("admin_token", "OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH");
      formData.append('user_email', values.username);
      formData.append("user_password", values.password);
      const response = await post("/user.php",formData);
      // Handle the response here
      if (response.status === 'success') {
        dispatch(setUserToken({ token: response.token }));
        storeToken(response.token);
        setProfile(response.profile);
        message.success('login sucessfully')
        navigate("/");
      } else if (response.status === 'Failed'){
        message.error(response.message);
      }

    } catch (error) {
      // Handle login error, e.g., show an error message
      // message.error(`${response.message}`)
      console.error("Login failed:", error);
      message.error('wrong email or password');
    } finally { 
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 px-5">
          <img className="widthimage" src="https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7963.jpg?t=st=1718266960~exp=1718270560~hmac=5b0a0bf4bcd5f5dbf789b7b28f38a9826a2e48b3fce7a0c368c60dbc6e806c2b&w=740" alt="login"></img>
        </div>
        <div className="col-md-6 px-5 d-flex align-items-center">
          <div className="account-card">
            <div className="title">
              <h1>Sign In</h1>
              <Breadcrumb
                items={[
                  {
                    title: <Link to="/">Home</Link>,
                  },
                  {
                    title: "Sign In",
                  },
                ]}
              />
            </div>
            <div className="account-form">
              <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                {/* <Form.Item
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Link className="login-form-forgot" to="/account/forgot-password">
                    Forgot password
                  </Link>
                </Form.Item> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                  >
                    Log in
                  </Button>
                  <br />
                  Or <Link to="/account/sign-up">register now!</Link>
                </Form.Item>

                {/* <Form.Item>
                  <div className="Google-Login-btn-con">
                    <GoogleLogin
                       clientId={clientId}
                       buttonText="Sign in with Google"
                       onSuccess={onSuccess}
                       onFailure={onFailure}
                       cookiePolicy={'single_host_origin'}
                    />
                  </div>
                </Form.Item> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
