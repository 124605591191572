import React from 'react'
import Footer from '../Footer'
import "../../Css/PrivacyPolicie.css"

const ShippingPolicy = () => {
    return (
        <>
            <div className="container my-5">
                <div className='privacy-policy-section-details'>
                    <h2 className="mt-4">Shipping Policy</h2>
                    <p>We are providing WORLDWIDE SHIPPING and are committed to ensuring 100% secure and quality shipping for customers by offering genuine products and safe and easy shipping methods for items shopped on this website</p>
                    <p>Our extremely reliable and trustworthy supply partners and shipping agents facilitate and guarantee a seamless and secured delivery method.</p>

                    <h3 className='privacy-heading'>Shipping Methods and Costs</h3>
                    <ul>
                        <li className='privacyli'>We deliver our products around the world and offer different shipping options as applicable.</li>
                        <li className='privacyli'>For more information, please feel free to contact our customer care service at Katariasstore@gmail.com or +91 93210 32490</li>
                        <li className='privacyli'>For shipments outside India, refer to the shipping charges below please check out:
                            <ul>
                                <li className='privacyli'>CHOOSE PRODUCT - ADD TO CART - CHECK YOUR ESTIMATE SHIPPING CHARGES</li>
                            </ul>
                        </li>
                    </ul>

                    <h3 className='privacy-heading'>Shipping and Handling</h3>
                    <ul>
                        <li className='privacyli'>We deliver to any or all postal addresses around the globe and in India at a minimal value. However, courier firms don't deliver to P.O. Box addresses, thus we request you to supply a full address with pin code/zip code.</li>
                        <li className='privacyli'>Our courier agents won't be able to give sign confirmation of delivery made in such cases. To make sure that no disputes arise because of this, kindly confirm that somebody is there to receive the package once courier delivery is tried.</li>
                    </ul>

                    <h3 className='privacy-heading'>When Do We Deliver</h3>
                    <p>All domestic and international ready-to-ship orders are shipped with in 7-10 days of order confirmation. If an order requires alterations or customization, it will take between 10 to 15 days to ship it after the order is placed.</p>

                    <h3 className='privacy-heading'>Tracking Your Order</h3>
                    <p>As soon as the product is dispatched, we'll email you concerning the cargo of your order, once the merchandise is handed over to the courier.</p>
                    <p>The email will contain the tracking number and courier company’s website detail alongside the date of expected delivery. You can also check the same order on our order status by logging in from your account.</p>
                    <p>Further data on the order status is provided by our customer care upon request.</p>

                    <h3 className='privacy-heading'>If My Order Has Been Placed, How Do I Know?</h3>
                    <p>Upon placing your order, you will see an order confirmation number on your screen. You’ll also receive an email confirmation from us. In case there are any issues with the processing of your orders, you will be notified via email.</p>
                    
                    <h3 className='privacy-heading'>Taxes & Custom Duties</h3>
                    <ul>
                        <li className='privacyli'>Taxes / Custom Taxes and Import Duties and courier charges are not in our control. They vary according to the rules of different countries and should be paid directly by the customer.</li>
                        <li className='privacyli'>For Indian Customers: All prices specified on the website are displayed inclusive of all taxes and duties.</li>
                        <li className='privacyli'>For International Customers: For customers outside India, duties and shipping charges are not included in the price. Customers are responsible for paying any import duties, customs fees, or local taxes. For more information, please contact us at Katariasstore@gmail.com</li>
                    </ul>

                    <p>Kataria.in offers Worldwide Shipping Facilities.</p>
                    <p>If you have any additional shipping-related queries, feel free to email us at Katariasstore@gmail.com or call customer care at +91 93210 32490.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ShippingPolicy