import React, { useEffect, useState } from 'react';
import "../../Css/OrderAddress.css";
import { GoPlus } from "react-icons/go";
import { Link } from 'react-router-dom';
import AddressModal from '../modal/AddressModal';
import AddressSelect from '../modal/AddressSelect';
import { getdeliveryaddress } from '../../services/localStorageServices';
import axios from 'axios';

const OrderAddress = ({selectedAddress,setSelectedAddress}) => {
    console.log("OrderAddress",selectedAddress)
    

    return (
        <>
            <div className='OrderDetials-con OrderAddresscon'>
                <div className='OrderDetials-billing-personal-details-con'>
                    <div className='OrderDetials-billing-personal-details'>
                        <h2>Shipping Details</h2>
                        <div className='OrderAddresscon-personal-deatails'>
                            {/* Display selected address */}
                            {selectedAddress ?
                                <div className='OrderAddresscon-personal-deatails-selected'>
                                    <p>
                                        {selectedAddress.address},<br />
                                        {/* {selectedAddress.address_2}<br /> */}
                                        {selectedAddress.city}, {selectedAddress.pincode},<br />
                                        {selectedAddress.state},<br />
                                        Phone Number: {selectedAddress.alternative_number}
                                    </p>
                                </div>
                                : <h5>No Address added</h5>}
                            {/* Pass the callback function to update selected address */}
                            <AddressSelect setSelectedAddress={setSelectedAddress} selectedAddress={selectedAddress} />
                            <AddressModal setSelectedAddress={setSelectedAddress} selectedAddress={selectedAddress} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderAddress;
