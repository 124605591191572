import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../Css/account.css";
// import SignUpImg from "../../Images/Login/SignUp.gif";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Checkbox, Form, Input, message, Upload } from "antd";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { BiLockOpen } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { setProfile, storeToken } from "../../services/localStorageServices";
// import GoogleLogin from "react-google-login";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { post } from "../../apiService";

export default function SignUp() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/")
    }
  }, [token])

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  

  const onFinish = async (values) => {
    setLoading(true);
    // alert('12345')

    try {
      const formData = new FormData();
      formData.append("action", "add");
      // formData.append("usertype", "user");
      formData.append("user_name", values.name);
      formData.append("admin_token", 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');
      formData.append("user_email", values.email);
      formData.append("user_phone", values.phone);
      formData.append("user_password", values.password);
      // formData.append("image", values.image);
      if (values.image && values.image.file) {
        formData.append('image', values.image.file);
    }
      // console.log("response1312", formData)
      const response = await post("/user.php", formData);

      if (response) {
        message.success('registration sucessfully')
         dispatch(setUserToken({ token: response.token }));
          storeToken(response.token);
          setProfile(response.profile);
          // Handle other properties in the response if needed
          navigate("/")
        if (response && response.token) {
          // dispatch(setUserToken({ token: response.data.token }));
          // storeToken(response.data.token);
          // setProfile(response.data.profile);
          // Handle other properties in the response if needed
          // navigate("/")
        } else {
          message.error(response.status)
          console.error("Signup Error - Invalid JSON data or missing properties:", response);
        }
      } else {
        message.error('registration error')
        console.error("Signup Error - Unsuccessful response", response);
      }
      // Handle success, redirect, or show a success message to the user.
    } catch (error) {
      console.error("Signup Error", error);
      message.error(error);
      // Handle errors, display an error message, or take appropriate action.
    } finally {
      setLoading(false);
    }
  };


  // google login 


  const clientId = "413735203093-tt019nmdfqqkff5vf69lhskudqtph6m0.apps.googleusercontent.com";

  const onSuccess = async (res) => {
    try {
      const formDataToSend = new FormData();
      // Append data to FormData object
      formDataToSend.append('User_Name', res.profileObj.name);
      formDataToSend.append('User_Email', res.profileObj.email);
      formDataToSend.append('action', "login");
      const response = await axios.post('https://jutiepie.in/api/Googlelogin.php?', formDataToSend);
      if (response) {
        dispatch(setUserToken({ token: response.token }));
        storeToken(response.token);
        setProfile(response.profile);
        navigate("/");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    console.log("LOGIN SUCCESS! Current user: ", res.profileObj)
  }

  const onFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res)
  }

  ////// End Google Login ///////


  return (
    <div className="row">
      <div className="col-md-6 px-5 d-flex align-items-center">
        <div className="account-card signUp">
          <div className="title">
            <h1>Sign Up</h1>
            <Breadcrumb
              items={[
                {
                  title: <Link to="/">Home</Link>,
                },
                {
                  title: "Sign Up",
                },
              ]}
            />
          </div>
          <div className="account-form">
            <Form
              name="normal_login"
              className="login-form"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <UserOutlined className="site-form-item-icon" />
                      }
                      placeholder="Full Name"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<MdOutlineEmail className="site-form-item-icon" />}
                      placeholder="Email"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone no.!",
                      },
                      {
                        min: 10,
                        max: 10,
                        message: "Please enter a valid Phone!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<BsTelephone className="site-form-item-icon" />}
                      placeholder="Phone No."
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<BiLockOpen className="site-form-item-icon" />}
                      placeholder="Password"
                      iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>

                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="Confirm Password"
                    name="password2"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The passwords do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={<BiLockOpen className="site-form-item-icon" />}
                      placeholder="Confirm Password"
                    />
                  </Form.Item>

                </div>
                {/* <div className="col-md-12">
                  <Form.Item label="User Image" name="image"
                  rules={[
                      {
                        required: true,
                        message: "User Image is required!",
                      },
                    ]}
                  >
                    <Upload beforeUpload={() => false} listType="picture">
                      <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                  </Form.Item>
                </div> */}
                {/* <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                </Form.Item> */}

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                  >
                    {loading ? "Signing up..." : "Sign Up"}
                  </Button>
                  <br></br>
                  Or <Link to="/account/sign-in">login now!</Link>
                </Form.Item>


              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <img className="widthimage" src="https://t4.ftcdn.net/jpg/03/86/51/03/360_F_386510351_03Qk3je4FGnVLo4vXRdOpoDWfZjtmajd.webp" alt="login"></img>
      </div>
    </div>
  );
}
