import React from "react";
import "../Css/NewHomeCat.css";
import RajputanaSherwani from "../Assert/new home about/Rajputana Sherwani.jpg";
import Sherwani from "../Assert/new home about/Sherwani.jpg";
import Jodhpuri from "../Assert/home-cat/Jodhpuri.jpeg";
import Indowestern from "../Assert/home-cat/Indowestern.jpeg";
import Kurtapyjama from "../Assert/home-cat/Kurta pyjama.jpeg";
import Kurtapyjamajacket from "../Assert/home-cat/Kurta pyjama jacket.jpeg";
import { Link } from "react-router-dom";
const NewHomeCat = () => {
  return (
    <>
      <section className="home-section-new-home-category">
        <div className="container">
          <div className="home-section-new-home-details-con-main">
            <div className="home-section-head-main-con">
              <h2>Top Selling Collections</h2>
              <h3>SHOP BY CATEGORY</h3>
            </div>
            <div className="home-section-second-main-new-category-con">
              <div className="row">
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="home-section-second-main-new-category-main-con">
                    <Link to="/product-detail-page/zig-zig-embroidery-work-jacket-jodhpuri">
                      <div className="home-section-second-main-new-category-image-con">
                        <img src={Jodhpuri} alt="Rajputana Sherwani" />
                      </div>
                      <div className="home-section-second-main-new-category-details">
                        <div className="home-section-second-main-new-category-details-con">
                          <h2>Jodhpuri</h2>
                          <h3>
                            <span>Jodhpuri</span>
                          </h3>
                          <div className="home-section-first-main-new-category-con-image-details-btn-con">
                            <button>Shop Now</button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="home-section-second-main-new-category-main-con">
                    <Link to="/product-detail-page/black-color-jacket-indowestern-set">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-image-con">
                          <img src={Indowestern} />
                        </div>
                        <div className="home-section-second-main-new-category-details">
                          <div className="home-section-second-main-new-category-details-con">
                            <h2>Indowestern</h2>
                            <h3>
                              <span>Indowestern</span>
                            </h3>
                            <div className="home-section-first-main-new-category-con-image-details-btn-con">
                              <button>Shop Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="home-section-second-main-new-category-main-con">
                    <Link to="/product/t-shirt">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-image-con">
                          <img src={Kurtapyjama} />
                        </div>
                        <div className="home-section-second-main-new-category-details">
                          <div className="home-section-second-main-new-category-details-con">
                            <h2>Kurta Pyjama</h2>
                            <h3>
                              <span>Kurta Pyjama</span>
                            </h3>
                            <div className="home-section-first-main-new-category-con-image-details-btn-con">
                              <button>Shop Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-6">
                  <div className="home-section-second-main-new-category-main-con">
                    <Link to="/product-detail-page/teal-blue-color-velvet-kurta-with-long-jacket-set">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-image-con">
                          <img src={Kurtapyjamajacket} />
                        </div>
                        <div className="home-section-second-main-new-category-details">
                          <div className="home-section-second-main-new-category-details-con">
                            <h2>Kurta pyjama jacket</h2>
                            <h3>
                              <span>Kurta pyjama jacket</span>
                            </h3>
                            <div className="home-section-first-main-new-category-con-image-details-btn-con">
                              <button>Shop Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewHomeCat;
