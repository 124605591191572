import React from "react";
import "../Css/HomeBanner.css";
import image01 from "../Assert/banner/banner01.png";
import image02 from "../Assert/banner/banner02.png";
import { Link } from "react-router-dom";
import { IoBagOutline } from "react-icons/io5";
const HomeBanner = (() => {
    return (
        <>
            <section className="new-home-banner-section">
                <Link to="/product/all">
                    <div className="new-home-banner-main-con">
                        <div className="new-home-banner-main-con-text-main-con">
                            <h2>Katarias</h2>
                            <h3>Now Live</h3>
                            <div className="new-home-banner-main-con-text-para-main-con">
                                <p>Explore the widest range</p>
                                <p>of collection</p>
                            </div>
                            <div className="new-home-banner-main-con-btn-main-con">
                                <button>
                                    <IoBagOutline /> SHOP NOW
                                </button>
                            </div>
                        </div>
                    </div>
                </Link>
            </section>
        </>
    )
});

export default HomeBanner;