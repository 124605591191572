import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { get, post } from "../../apiService";

const AddressSelect = ({ setSelectedAddress, selectedAddress }) => {

  const tokenid = localStorage.getItem("access_token")
  console.log("tokenid", tokenid)
  const profile = JSON.parse(localStorage.getItem('profile'));
  console.log(profile);
  const [adressList, setAddressList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState([])
  const showModal = async () => {
    setIsModalOpen(true);

    try {
      const response = await get(`/address.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&user_id=${profile.user_id}&action=fetch&limit=10&page=1`);
      const addressListData = response;
      console.log("addressListData", addressListData)
      setAddressList(addressListData.list)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOk = async () => {
    const formData = new FormData();
    formData.append('user_id', profile.user_id);
    formData.append('action', 'update');
    formData.append('current_active', '1');
    formData.append('address_id', deliveryAddress.address_id);
    formData.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH')

    try {
      const response = await post('/address.php',formData)

      if(response){
        console.log('Data received:', response);
        
        setSelectedAddress(deliveryAddress)
      }
    } catch (error) {
      console.log(error);
    } finally{
      setIsModalOpen(false);
    }
    // fetch('/address.php', {
    //   method: 'POST',
    //   body: formData
    // })
    //   .then(response => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error('Network response was not ok.');
    //   })
    //   .then(data => {
    //     console.log('Data received:', data);
    //     setIsModalOpen(false);
    //     setSelectedAddress(deliveryAddress)
    //   })
    //   .catch(error => {
    //     console.error('There was a problem with the fetch operation:', error);
    //   });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
console.log("addres selected "+ JSON.stringify(deliveryAddress));

  return (
    <>
      <Button type="primary" onClick={showModal} >
        Select Shipping Address
      </Button>
      <Modal
        title="Select Address"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="OrderDetials-billing-personal-detailsOrderAddresscon">
          {adressList && adressList?.map((elem, index) => (
            <div key={elem.address_id || index} className="OrderAddresscon-personal-details">
              <input
                type="radio"
                name="address"
                id={"id" + elem.address_id}  // It's better to use the address_id if it's unique
                className="form-input-check"
                onChange={() => setDeliveryAddress(elem)}
                checked={deliveryAddress.address_id === elem.address_id}  // Compare ids to check the radio button
              />
              <label htmlFor={"id" + elem.address_id}>  {/* Use elem.address_id for htmlFor */}
                <div className="row">
                  <div className="col-sm-12"> Address: {elem.address}</div>
                  <div className="col-sm-6">City: {elem.city}</div>
                  <div className="col-sm-6"> Pin code: {elem.pincode}</div>
                  <div className="col-sm-6">State: {elem.state}</div>
                  <div className="col-sm-6"> Phone Number: {elem.alternative_number}</div>
                
                </div>
               {/* Address: {elem.address}<br />
                City: {elem.city}<br />
                Pin code: {elem.pincode}<br />
                State: {elem.state}<br />
                Phone Number: {elem.alternative_number} */}
              </label>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default AddressSelect;
