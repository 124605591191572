import React from 'react'
import "../Css/RecommendationCard.css";

const RecommendationCard = ({ id, name, price, img }) => {
    const calculateOfferPrice = (price) => {
        return Math.round(price * (1 - 0.33));
    };
    return (
        <>
            <div className="product" bis_skin_checked={1}>
                <a
                    className="product-link"
                    href="/in/mustard-yellow-lehariya-printed-kurta-set-for-men.html"
                    target="_blank"
                >
                    <span className="product-image-container">
                        <img
                            className="product-image"
                            // data-src="https://newcdn.kalkifashion.com/media/tagalys/product_images/m/u/mustard_yellow_lehariya_printed_kurta_set_for_men-sg235780_4_.jpg"
                            alt="Mustard Yellow Lehariya Printed Kurta Set For Men"
                            src={img}
                        />
                    </span>
                    <div className="product-details" bis_skin_checked={1}>
                        <div className='clothCard-product-deatils-main-con-price'>
                            <div className='clothCard-product-deatils-main-con-mainprice'>
                                <label> RS. {calculateOfferPrice(price)} INR </label>
                            </div>
                            <div className='clothCard-product-deatils-main-con-offerprice'>
                                <label> RS. {price} INR </label>
                                {/* <span className='clothCard-product-deatils-main-con-discount'>(33% OFF)</span> */}
                            </div>
                        </div>
                    </div>
                    <div className='clothCard-product-detials-main-con-offer-per'>
                                    <h4>(33% OFF)</h4>
                                </div>
                </a>
            </div>

        </>
    )
}

export default RecommendationCard