import React, { useEffect, useRef, useState } from 'react';
import audioFile from '../Assert/demo.mp3';
import "../Css/AudioPlayer.css";
import { IoVolumeMedium } from "react-icons/io5";
import { IoVolumeMute } from "react-icons/io5";

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlay = () => {
    audioRef.current.play().then(() => {
      setIsPlaying(true);
    }).catch(error => {
      console.error("Autoplay prevented. User interaction required.");
    });
  };

  const handleStop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Reset to start
    setIsPlaying(false);
  };

  useEffect(() => {
    // Set audio volume to 70%
    if (audioRef.current) {
      audioRef.current.volume = 0.1;
    }

    // Check if user has previously interacted
    const hasInteracted = sessionStorage.getItem('userInteracted');
    if (hasInteracted) {
      // Try playing audio automatically on refresh
      audioRef.current.play().catch(error => {
        console.error("Failed to autoplay audio:", error);
      });
    }

    // Set up event listener for the first interaction
    const handleUserInteraction = () => {
      sessionStorage.setItem('userInteracted', true); // Mark user as interacted
      audioRef.current.play().catch(error => {
        console.error("Failed to play audio:", error);
      });
      document.removeEventListener('click', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);
    return () => document.removeEventListener('click', handleUserInteraction);
  }, []);

  return (
    <div className='audio'>
      <audio ref={audioRef}>
          <source src={audioFile} type="audio/mpeg" />
      </audio>
      <button onClick={isPlaying ? handleStop : handlePlay}>
        {isPlaying ? <IoVolumeMedium /> : <IoVolumeMute />}
      </button>
    </div>
  );
};

export default AudioPlayer;
