import React, { useEffect, useLayoutEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "../Css/Product.css";
import Breadcrumbs from "../Components/Breadcrumbs";
import ClothCard from "../Components/ClothCard";
import Footer from "../Components/Footer";
import { Link, useParams } from "react-router-dom";
import FilterSort from "../Components/FilterSort";
import { get } from "../apiService";
import { Pagination, Slider, Spin } from "antd";
import { MetaTags } from "react-meta-tags";

const Product = () => {
  const { slug } = useParams();
  const [showGroomList, setShowGroomList] = useState(false);
  const [loading, setLoading] = useState(false);
  const cards = Array.from({ length: 10 });
  const [Products, setProducts] = useState([]);
  const [allcategory, setAllCategory] = useState([]);
  console.log("allcategory", allcategory)
  const [productAllImages, setProductAllImages] = useState([]);
  const [filterColor, setFilterColor] = useState([]);
  const [filterSizes, setFilterSizes] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 25000]);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
    total: 0,
  });
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    fetchColor();
    fetchSizes();
  }, []);
  // fet

  useEffect(() => {
    const category = localStorage.getItem("category");

    // Check if 'category' exists and parse it to an array
    if (category) {
      const categoryArray = JSON.parse(category);
      const getCategory = categoryArray.find((v) => v.category_slugs === slug);
      setAllCategory(categoryArray);
      console.log(getCategory);

      if (slug === "all") {
        // Fetch all products
        fetchProducts();
      } else if (slug === "newArrival") {
        // Fetch new arrivals without passing category_id
        fetchProducts("newArrival");
      } else if (slug !== "") {
        // Fetch products by category
        fetchProducts(getCategory.category_Id);
      }
      else if (getCategory) {
        // Fetch products by category
        fetchProducts(getCategory.category_Id);
      }
    }
  }, [pagination.current, pagination.pageSize, slug]);

  const fetchProducts = async (id) => {
    setLoading(true);
    try {
      let response;

      if (id === undefined) {
        // Fetch all products
        response = await get(
          `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${pagination.pageSize}&page=${pagination.current}`
        );
      } else if (id === "newArrival") {
        // Fetch new arrivals, exclude category_id
        response = await get(
          `/product.php?action=fetchNewArival&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&page=${pagination.current}&limit=${pagination.pageSize}`
        );
      } else {
        // Fetch products by category
        response = await get(
          `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${pagination.pageSize}&page=${pagination.current}&category_id=${id}`
        );
      }

      if (response && response.list) {
        console.log(response.list);
        setProducts(response.list);

        // If you have pagination information from the API, such as total number of items:
        const totalItems = response.total || 0;

        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalItems,
        }));
      }
    } catch (error) {
      console.log("Error fetching product category:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (newPagination) => {
    const { current, pageSize } = newPagination;
    setPagination((prevData) => ({
      ...prevData,
      current,
      pageSize,
    }));
  };

  const fetchColor = async () => {
    try {
      const response = await get(
        `/product.php?action=fetchColors&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`
      );
      setFilterColor(response);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const fetchSizes = async () => {
    try {
      const response = await get(
        `/product.php?action=fetchSizes&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`
      );
      setFilterSizes(response);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const fetchPriceRangeProducts = async () => {
    setLoading(true);
    try {
      const response = await get(
        `/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&price_from=${priceRange[0]}&price_to=${priceRange[1]}&page=${pagination.current}&limit=${pagination.pageSize}`
      );
      console.log("price Products:", response);
      setProducts(response.list);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSliderChange = (value) => {
    // console.log(value);
    if (value[0] >= value[1]) {
      return;
    }
    setPriceRange(value);
  };

  return (
    <>
      <MetaTags>
        <title>Readynade Kurta | Kataria</title>
        <meta title="Readynade Kurta | Kataria" />
        <meta name="description" content="Discover stylish Readymade Kurtas at Kataria, offering a blend of traditional and contemporary designs perfect for every occasion." />
        <meta name="keywords" content="Men's Suiting,Shirting ,Readynade Kurta,Best Suitings,Best Kurtas,Kurta(Light Blue, Reyon),Kurta(Blood Red, Georgette),Kurta(Multi – color. ,Reyon),Kurta(Blue & White , Art Silk),Kurta(Light onion color , Art Silk),Kurta(Lavender, Reyon),Kurta(Peach - Art Silk),Kurta(Base color - Cream, Art Silk),Kurta(Multi colour Kurta, Art Silk),Kurta(Sea Green , Art Silk),Men's Suiting,Shirting ,Readynade Kurta,Best Suitings,Best Kurtas,Kurta(Light Blue, Reyon),Kurta(Blood Red, Georgette),Kurta(Multi – color. ,Reyon)" />
        <link rel="canonical" href="https://katariashubli.com/readynade-kurta" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Readynade Kurta | Kataria" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://katariashubli.com/" />
        <meta property="og:description" content="Discover stylish Readymade Kurtas at Kataria, offering a blend of traditional and contemporary designs perfect for every occasion." />
        <meta property="og:image" content="https://katariashubli.com/static/media/kartaria%20logo.61ab17e04111050f3cc9.png" />
      </MetaTags>
      <Breadcrumbs />
      <section>
        <div className="container">
          <div className="main-product-page">
            <div className=" product-page-filter-left">
              <h5> FILTERS</h5>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title">Price</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div>
                      <div>
                        <Slider
                          range={{
                            draggableTrack: true,
                          }}
                          min={0}
                          max={25000}
                          defaultValue={priceRange}
                          onChange={handleSliderChange}
                        />
                        <div>
                          Selected Price Range: ₹{priceRange[0]} - ₹
                          {priceRange[1]}
                        </div>

                        <button
                          onClick={fetchPriceRangeProducts}
                          className="btn text-center filter-apply-btn mb-2"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title"> Sub Categories</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className="main-sub-categories-list">
                      {allcategory?.map((v, i) => (
                        <Link to={`/product/${v.category_slugs}`}>
                          <span className=""> {v.category_name}</span>
                        </Link>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="3">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title"> Color</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className="main-color-list row pr-4 mt-2">
                      {filterColor.map((v, i) => (
                        <div className="col-md-2">
                          <div
                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                            bis_skin_checked={1}
                          >
                            <input
                              className="input hidden"
                              name="amshopby[confi_color][]"
                              defaultValue={1152}
                              type="radio"
                            />
                            <a
                              href=""
                              rel="nofollow"
                              data-label="Brown"
                              className="am-swatch-link inline-block"
                            >
                              <div
                                className=" swatch-option border border-[#F0F0F0] color w-5 h-5 rounded-full mb-2 mr-3 "
                                data-am-js="swatch-item"
                                option-type={1}
                                option-id={1152}
                                option-label="Brown"
                                option-tooltip-thumb=""
                                option-tooltip-value="#702219"
                                data-option-type={1}
                                data-option-id={1152}
                                data-option-label="Brown"
                                data-option-tooltip-thumb=""
                                data-option-tooltip-value="#702219"
                                style={{ backgroundColor: `${v.color_code}` }}
                                bis_skin_checked={1}
                              />
                              <div
                                className="am-shopby-swatch-label hidden"
                                bis_skin_checked={1}
                              >
                                <span className="label">Brown</span>
                                <span className="count">1</span>
                                <span className="filter-count-label">item</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <hr />
                  <Accordion.Header className="container">
                    <h6 className=" accordion-title"> Select Size</h6>
                  </Accordion.Header>
                  <Accordion.Body className="container">
                    <div className="main-size-list row mt-2">
                      {filterSizes?.map((v, i) => (
                        <div className="col-md-4">
                          <div
                            className="am-swatch-wrapper item swatch-option-link-layered inline-block "
                            bis_skin_checked={1}
                          >
                            <input
                              className="input hidden"
                              name="amshopby[confi_size][]"
                              defaultValue={5115}
                              type="checkbox"
                            />
                            <a
                              href="https://www..com/in/ethnic/best-man.html?confi_size=5115"
                              rel="nofollow"
                              data-label={36}
                              className="am-swatch-link inline-block"
                            >
                              <div
                                className=" swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition rounded-[30px] "
                                data-am-js="swatch-item"
                                option-type={0}
                                option-id={5115}
                                option-label={36}
                                option-tooltip-thumb=""
                                option-tooltip-value=""
                                data-option-type={0}
                                data-option-id={5115}
                                data-option-label={36}
                                data-option-tooltip-thumb=""
                                data-option-tooltip-value=""
                                style={{}}
                                bis_skin_checked={1}
                              >
                                {v.product_size}
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
                <hr />
              </Accordion>
            </div>
            <div className="product-page-filter-right ">
              <div
                className={`shadow dropdown-groosmen-outfits mt-2 ${showGroomList ? "" : "hidden"
                  }`}
              >
                <div className="groosmen-outfits-option">
                  <div className="dropdown-inner-list-groosmen-outfits">
                    <div>
                      Weeding <label className="text-muted"> 22</label>
                    </div>
                  </div>
                  <div className="dropdown-inner-list-groosmen-outfits">
                    <div>
                      Weeding <label className="text-muted"> 22</label>
                    </div>
                  </div>
                </div>
              </div>
              <Spin spinning={loading}>
                <div className="row">
                  {Products?.map((v, index) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-6 col-clothCard mb-5"
                      key={index}
                    >
                      <Link to={`/product-detail-page/${v.slug}`}>
                        <ClothCard
                          pName={v.product_name}
                          pDes={v.product_desc}
                          pImage={v.colors?.[0]?.image?.[0]?.images} // First image
                          pImagesSecond={
                            v.colors?.[0]?.image?.[1]?.images // Check if the second image exists
                              ? v.colors[0].image[1].images // Use the second image if it exists
                              : v.colors?.[0]?.image?.[0]?.images // Otherwise, use the first image
                          }
                          pPrice={v.colors?.[0]?.product_price || "N/A"}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </Spin>
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={(page, pageSize) =>
                  handleTableChange({ current: page, pageSize })
                }
                showSizeChanger
                pageSizeOptions={["10", "20", "50", "100"]}
                className="user-list-pagination"
              />
              <div className="product-cart-list-main-product-main-con">
                <h4>Designer Ethnic Wear for The Best Man</h4>
                <p className="designer-ethnic-wear">
                  Looking for the perfect outfit for your Yaar Ki Shaadi? We got
                  you covered. Kataria's has the best collection of
                  designer ethnic men’s wear that will make you look like the
                  most handsome best man at your brother’s wedding. Men’s
                  fashion has come a long way in the past years. From plain
                  kurtas to fancy silhouettes, men’s fashion trends have become
                  more experimental and fun. It is time to ditch the boring
                  blacks and blues and jump into a world of lively hues this
                  season.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FilterSort />
      <Footer />
    </>
  );
};

export default Product;
