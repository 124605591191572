import { Rate } from "antd";
import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import "../Css/CustomerReviewCard.css";
import googleReview from "../Assert/googleReview.png";
// import googleReview form '../../'

const CustomerReviewCard = ({ description, rate, name }) => {
  return (
    <>
      <div className="testimonials main-home-section-testimonials grid section-padding">
        {/* <div className=' gap-2 customer-review-card-start'>
                    <div className='customer-review-card pb-2'>
                        <div className='user-googleReview-div'>
                            <FaUserCircle className='fs-4' />
                            <img className='user-googleReview-img' src={googleReview} />
                        </div>
                        <h6 className='ml-1'> {name} </h6><label> <BsFillPatchCheckFill className='customer-verified' /></label>
                    </div>
                    <div>
                        <Rate disabled defaultValue={rate} />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </div> */}

        <div
          className="item wow fadeInUp slick-slide"
          data-wow-delay=".3s"
          data-slick-index={3}
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
          aria-hidden="true"
          tabIndex={-1}
        >
          {/* <span className="quote-icon">
                      <img src={quote} alt="" />
                    </span> */}
          <div className="cont">
            <p className="playfont" dangerouslySetInnerHTML={{ __html: description }}/>
          </div>
          <div className="info">
            <div className="author">
              {/* <img src={JasveerArora} alt="" /> */}
            </div>
            <h6>{name}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerReviewCard;
