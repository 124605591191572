import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import required modules
import { Autoplay } from 'swiper/modules';
import "swiper/css";

const OfferSwiper = () => {
    return (
        <>
            <section className='bg-black text-center text-white text-uppercase py-1 fs-6'>
                <div>
                    <Swiper className="mySwiper"
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}>
                        <SwiperSlide>
                            <div className='home-auto-scroller'>
                                35 years of legacy
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='home-auto-scroller'>
                                Quality assurance
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='home-auto-scroller'>
                            flat 33% off on all products (INAUGURAL OFFER)
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default OfferSwiper